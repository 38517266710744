<template>
  <div>
    <div class="fs-19 font-weight-bold mb-2">
      {{
        type === "edit"
          ? "Edit the vehicle information"
          : "Please fill in your vehicle information"
      }}
    </div>
    <b-card class="px-2 rounded-2 shadow-sm mb-2">
      <div class="text-right fs-14 mb-4" v-if="type !== 'edit'">
        <span
          class="green-text cursor-pointer font-weight-thin"
          @click="goToTop"
          >Try my rego again</span
        >
      </div>
      <div>
        <b-row>
          <b-col cols="12">
            <custom-select
              label="State"
              v-model="updateData.location"
              :options="states"
              :error="$v.updateData.location.$error"
              helperText="Please select state"
              name="state"
              :isFocus="curStepInput === 1"
              @setNextInput="setNextInputNumber"
            />
          </b-col>
          <b-col cols="12">
            <custom-autocomplete-input
              label="Make"
              v-model="updateData.make"
              :options="makes"
              :error="$v.updateData.make.$error"
              :isFocus="curStepInput === 2"
              helperText="Please enter make"
              name="make"
              @setNextInput="setNextInputNumber"
              placeholder="Enter make"
            />
          </b-col>
          <b-col cols="12">
            <custom-select
              label="Model"
              v-model="updateData.model"
              :selectDisabled="this.models.length === 1"
              :options="models"
              :error="$v.updateData.model.$error"
              helperText="Please select model"
              name="model"
              :isFocus="curStepInput === 3"
              @setNextInput="setNextInputNumber"
            />
          </b-col>
          <b-col cols="12">
            <custom-select
              label="Colour"
              v-model="updateData.color"
              :options="colors"
              :error="$v.updateData.color.$error"
              helperText="Please select colour"
              name="color"
              :isFocus="curStepInput === 4"
              @setNextInput="setNextInputNumber"
            />
          </b-col>
          <b-col cols="12">
            <custom-select
              label="Year"
              v-model="updateData.year"
              :options="years"
              helperText="Please select year"
              :error="$v.updateData.year.$error"
              name="year"
              :isFocus="curStepInput === 5"
              @setNextInput="setNextInputNumber"
            />
          </b-col>
        </b-row>
      </div>
      <btn-group
        class="mt-3"
        :isHasIcon="type !== 'edit'"
        :okText="type === 'edit' ? 'Update' : 'Next'"
        :cancelText="type === 'edit' ? 'Cancel' : 'Back'"
        :back="type !== 'edit' ? 'top-page' : null"
        @onOk="onUpdate"
        @onCancel="onCancel"
        :okBtnDisable="!$v.updateData.$invalid"
        :query=this.$route.query
      />
    </b-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  FETCH_DETAIL,
  SET_BASIC_INFO,
  SET_INFO,
  FETCH_MAKES,
  FETCH_MODELS,
} from "@/store/info/actions";
import { yearRange, modelYearRange } from "../../helper/constant";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    BtnGroup: () => import("../common/BtnGroup.vue"),
    CustomSelect: () => import("../common/CustomSelect.vue"),
    CustomAutocompleteInput: () => import("../common/CustomAutocompleteInput.vue"),
  },
  props: ["type", "vehicleData"],
  data() {
    return {
      // submitted: false,
      onChange: false,
      onFirst: true,
      curStepInput: 1,
      inputStep: [
        {
          step: 1,
          key: "location",
        },
        {
          step: 2,
          key: "make",
        },
        {
          step: 3,
          key: "model",
        },
        {
          step: 4,
          key: "color",
        },
        {
          step: 5,
          key: "year",
        },
        {
          step: 6,
          key: "",
        },
      ],
      beforeUpdateBadges: this.$store.getters.badges,
    };
  },
  computed: {
    ...mapState({
      makes: (state) => state.info.makes,
      models: (state) => state.info.models,
      colors: (state) => state.info.colors,
      updateData: state => state.info.curVehicleInfo
    }),
    years: function () {
      const temp = yearRange();
      return temp;
    },
    states: function () {
      return ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
    },
    modelYears: function () {
      const temp = modelYearRange();
      return temp;
    },
    // isNextAble() {
    //   return this.updateData.make &&
    //       this.updateData.model &&
    //       this.updateData.year &&
    //       this.updateData.color &&
    //       this.updateData.location
    // },
    // validate() {
    //   return this.isNextAble
    // }
  },
  methods: {
    onUpdate() {
      // this.submitted = true
      this.$v.updateData.$touch();
      if (!this.$v.updateData.$invalid) {
        if (this.type === "edit")
          this.$store.dispatch(SET_BASIC_INFO, this.updateData);
        if (this.type === "create") {
          this.$store.dispatch(SET_INFO, this.updateData);
          this.$store.dispatch("setProgress", 50);
          this.$router.push({ name: "vehicle-info", query: this.$route.query });
        }
        this.$emit("onCancel");
        if (this.onChange) {
          this.$emit("changeData");
          this.onChange = false;
        }
      }
    },
    onCancel() {
      this.$store.dispatch("setBadges", this.beforeUpdateBadges);
      this.$emit("onCancel");
      this.onChange = false;
    },
    goToTop() {
      this.$router.push({ name: "top-page" });
    },
    setNextInputNumber() {
      for (let i = 0; i < this.inputStep.length; i++) {
        const item = this.inputStep[i];
        if (!this.updateData[item.key] || item.key === "") {
          this.curStepInput = item.step;
          break;
        }
      }
    },
  },
  validations: {
    updateData: {
      make: {
        required,
      },
      model: {
        required,
      },
      year: {
        required,
      },
      color: {
        required,
      },
      location: {
        required,
      },
    },
  },
  watch: {
    "updateData.make": function (val) {
      if (val) {
        this.$store.dispatch(FETCH_MODELS, {
          make: val,
        });
        this.updateData.model = "";
        this.updateData.color = "";
        this.curStepInput = 2;
        this.onChange = true;
      }
    },
    "updateData.model": function (val) {
      if (val) {
        this.$store.dispatch(FETCH_DETAIL, {
          make: this.updateData.make,
          model: val,
        });
        this.updateData.color = "";
        this.curStepInput = 3;
        this.onChange = true;
      }
    },
    curStepInput: function () {
      let progress = 0;
      for (let i = 0; i < this.inputStep.length; i++) {
        const item = this.inputStep[i];
        if (this.updateData[item.key] && i === 4) {
          progress = progress + 9;
        } else if (this.updateData[item.key] && item.key !== "") {
          progress = progress + 10;
        }
      }
      this.$store.dispatch("setProgress", progress);
    },
  },
  created() {
    // this.setNextInput()
  },
  mounted() {
    if (this.makes.length === 0) {
      this.$store.dispatch(FETCH_MAKES);
    }
    // if (this.models.length === 0 && this.type === 'edit') {
    //   this.$store.dispatch(FETCH_MODELS, { make: this.updateData.make })
    // }
    if (
      this.type === "edit" ||
      (this.makes !== "" && this.models.length === 0)
    ) {
      this.$store.dispatch(FETCH_MODELS, { make: this.updateData.make });
    }
  },
};
</script>